export const fileOperations = {
  methods: {
    getExtension(filename) {
      return filename.split(".").pop();
    },
    sizeFormatter(size) {
      // #2**10 = 1024
      const power = 2**10
      var n = 0
      var powerN = {0 : 'B', 1: 'KB', 2: 'MB', 3: 'GB', 4: 'TB'}
      while (size > power) {
          size /=  power
          n += 1
      }
      size = Math.round(size * 10) / 10

      return size.toString() + ' ' + powerN[n]
    }
  }
}
