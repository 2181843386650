<template>
  <div class="auditItemUpload">
    <div class="gavc-text-center">
      <div v-if="!disabled" class="gavc-upload-file__header gavc-margin-bottom">
        <div class="gavc-upload-file__input gavc-padding-medium">
          <input
            type="file"
            id="input-file"
            accept=".jpg,.jpeg,.png,.pdf"
            @change="newDocumentUploaded($event)"
          >
          <label for="input-file"></label>
          <i class="gavc-icon gavc-icon-upload"></i>
          <p class="gavc-question gavc-margin-top">
            Glisser déposer ou sélectionner un fichier (png, pdf, jpg)
          </p>
        </div>
      </div>
      <p v-if="isBadFormat" class="gavc-text-rose text-left">
        <i class="gavc-icon gavc-icon-cross" /> Veuillez charger un fichier de type pdf, jpg, jpeg ou png
      </p>
      <p v-if="isBadName" class="gavc-text-rose text-left">
        <i class="gavc-icon gavc-icon-cross" /> Le nom de votre fichier doit comprendre moins de 500 caractères
      </p>
      <div class="gavc-question text-left">
        <small v-if="documents.length > 1">
          {{documents.length}} documents
        </small>
        <small v-else>
          {{documents.length}} document
        </small>
      </div>
      <ul class="gavc-upload-file__list">

        <li v-for="item in documents" :key="item.id" class="gavc-upload-file__item">
          <div class="gavc-upload-file__infos">

            <span class="gavc-upload-file__infos--text">
              <p v-if="item.file_name.length <= 20" class="gavc-name gavc-text-small">{{item.file_name}}</p>
              <p v-else class="gavc-name gavc-text-small">{{item.file_name.substring(0,20) + '...'}}</p>
              <p class="gavc-weight">{{sizeFormatter(item.file_size)}}</p>
            </span>
            <button
              class="gavc-upload-file__dl-btn"
              style="background-color: transparent !important; border: 0 !important;"
              @click="$emit('document-download', {'id': item.id, 'name': item.file_name})"
              v-b-tooltip.hover title="Télécharger le document"
            >
              <i class="gavc-icon gavc-icon-download"></i>
            </button>

          </div>
          <div class="text-light" v-if="item.is_validated">
            <v-icon  class="mr-2 text-light" name="check-circle" /> Validé
          </div>
          <div class="text-light" v-else-if="!disabled">
            <v-icon class="mr-2 text-light" name="sync" /> En cours de validation
          </div>
          <div class="text-light" v-else>
            <v-icon  class="mr-2 text-light" name="sync" /> A valider
          </div>
          <button
            v-if="!disabled"
            class="gavc-upload-file__remove-btn"
            style="background-color: transparent !important; border: 0 !important;"
            @click="$emit('document-deleted', item.id)"
            v-b-tooltip.hover title="Supprimer le document"
          >
            <i class="gavc-icon gavc-icon-cross"></i>
          </button>
          <button
            v-else-if="!item.is_validated"
            class="gavc-upload-file__remove-btn"
            style="background-color: transparent !important; border: 0 !important;"
            @click="$emit('item-validated', item.id)"
            v-b-tooltip.hover title="Valider le document"
          >
            <i class="gavc-icon gavc-icon-check"></i>
          </button>
          <button
            v-else
            class="gavc-upload-file__remove-btn"
            style="background-color: transparent !important; border: 0 !important;"
            @click="$emit('item-unvalidated', item.id)"
            v-b-tooltip.hover title="Invalider le document"
          >
            <i class="gavc-icon gavc-icon-cross"></i>
          </button>
        </li>
      </ul>
    </div>
    <div v-if="justificatifs.length === 0 && !disabled">
      <div class="gavc-field">
        <div class="gavc-radio-checkbox" style="margin-top: 10px !important; margin-bottom: 10px !important;">
          <div class="gavc-checkbox">
            <input v-model="wantsToAddJustificatif" type="checkbox" :id="`wantsToAddJustificatif-1-${type}`" :name="`wantsToAddJustificatif-1-${type}`">
            <label class="gavc-label" :for="`wantsToAddJustificatif-1-${type}`">
                Vous n'avez pas un ou plusieurs documents demandés ?
                <i class="gavc-icon gavc-icon-check" ></i>
            </label>
          </div>
        </div>
      </div>
      <div v-if="wantsToAddJustificatif">
        <div class="gavc-field mb-4">
          <textarea
            class="gavc-textarea"
            id="registration-step-1-chargerDocument-group"
            maxlength="1000"
            placeholder="Précisez les raisons pour lesquelles vous ne pouvez pas charger le document"
            @change="$emit('justificatif-added', $event)"
            type="text">
          </textarea>
        </div>
      </div>
    </div>
    <div class="mb-4" v-else-if="justificatifs.length > 0">
      <div>
        <strong> Justificatif </strong>
        (
        <span v-if="justificatifs[0].is_validated">
          <v-icon class="text-success" name="check-circle" /> Validé
        </span>
        <span v-else-if="!disabled">
          <v-icon class="text-secondary" name="check-circle" /> En cours de validation
        </span>
        <span v-else>
          <v-icon class="text-secondary" name="check-circle" /> A valider
        </span>
        ) :
        <button
          v-if="!disabled"
          class="gavc-upload-file__remove-btn float-right"
          style="background-color: transparent !important; border: 0 !important;"
          @click="$emit('document-deleted', justificatifs[0].id)"
          v-b-tooltip.hover title="Supprimer le justificatif"
        >
          <i class="gavc-icon gavc-icon-cross"></i>
        </button>
        <button
          v-else-if="!justificatifs[0].is_validated"
          class="gavc-upload-file__remove-btn float-right"
          style="background-color: transparent !important; border: 0 !important;"
          @click="$emit('item-validated', justificatifs[0].id)"
          v-b-tooltip.hover title="Valider le justificatif"
        >
          <i class="gavc-icon gavc-icon-check"></i>
        </button>
        <button
          v-else
          class="gavc-upload-file__remove-btn float-right"
          style="background-color: transparent !important; border: 0 !important;"
          @click="$emit('item-unvalidated', justificatifs[0].id)"
          v-b-tooltip.hover title="Invalider le justificatif"
        >
          <i class="gavc-icon gavc-icon-cross"></i>
        </button>
      </div>
      <p style="white-space: pre-line">
        {{justificatifs[0].comment}}
      </p>
    </div>

  </div>
</template>

<script>
import { fileOperations } from '@/mixins/documentMixins'
export default {
  name: 'auditItemUpload',
  data: function () {
    return {
      allowedExtensions: ['pdf', 'jpg', 'jpeg', 'png'],
      isBadFormat: false,
      isBadName: false,
      wantsToAddJustificatif: false
    }
  },
  props: {
    disabled: {
      type: Boolean,
      default: true
    },
    items: {
      type: Array,
      default() {
        return []
      }
    },
    type: {
      type: String,
    }
  },
  mixins: [
    fileOperations
  ],
  computed: {
    documents () {
      return this.items.filter(item => item.has_file)
    },
    justificatifs () {
      return this.items.filter(item => !item.has_file)
    }
  },
  methods: {
    newDocumentUploaded(eventDocument) {
      this.isBadName = false
      this.isBadFormat = false
      if (!this.allowedExtensions.includes(this.getExtension(eventDocument.target.files[0].name))) {
        this.isBadFormat = true
      }
      else if (eventDocument.target.files[0].name.length > 500) {
        this.isBadName = true
      }
      else {
        this.$emit('document-uploaded', eventDocument)
      }
    }
  }
}
</script>
